import { track } from '@amplitude/analytics-browser';
import { MediaReport } from '../lib/types';

type ReportPreviousContentOpenedAnalyticsParams = MediaReport & {
  pageSpecialty?: string;
};

export function reportPreviousContentOpenedAnalytics({
  id,
  seriesType,
  chapterTitle,
  episodeTitle,
  currentPoint,
  duration,
  mediaType,
  pageSpecialty
}: ReportPreviousContentOpenedAnalyticsParams) {
  // keep legacy Amplitude event name
  track('Previous_Player', {
    'Media.id': id,
    'Media.series_type': seriesType,
    'Media.chapter_title': chapterTitle,
    'Media.episode_title': episodeTitle,
    'Media.current_point': currentPoint,
    'Media.duration': duration,
    'Media.media_type': mediaType,
    page_specialty: pageSpecialty,
    path: window.location.pathname
  });
}
