import { EpisodeTypeShortname } from '../schema/webEpisode/episodeType';

/* eslint-disable camelcase */
export enum EpisodeTypeNumber {
  EMRAP = 0,
  C3 = 10,
  EMA = 11,
  HD = 2,
  CRUNCH_TIME_EM = 12,
  LLSA = 13,
  ROP = 14,
  CRUNCH_TIME_FM = 15,
  NONE = 100
}

export interface Artwork {
  height: number;
  width: number;
  url: string;
  shortname: string;
}

export interface Faculty {
  fullname: string;
  id: number;
}

export interface SortOption {
  key: string;
  label: string;
  dataField: string;
  sortBy: string;
}

export interface AudienceOption {
  key: string;
  label: string;
  dataField: string;
  value: EpisodeTypeNumber[];
}

export type ChapterTab =
  | 'Summary'
  | 'Conversation'
  | 'Crunchy Qs'
  | 'Smart Cards'
  | 'CME Credits';

export enum ConferencePhase {
  BEFORE = 'before',
  DURING = 'during',
  AFTER = 'after'
}

/* ElasticSearch Types */

export interface ESArtwork {
  id?: number;
}

export interface ESEmrapGuideEpisodeType {
  id: number;
  display?: string;
}

export interface ESEmrapGuideEpisodeCME {
  hours?: number;
}

export interface ESThumbnail {
  720: ESThumbnailDimension;
  480: ESThumbnailDimension;
}

export interface ESThumbnailDimension {
  width: number;
  height: number;
  path: string;
}

export type ESEmrapGuideDoc = ESEmrapGuideEpisodeDoc | ESEmrapGuideChapterDoc;

export interface ESEmrapGuideEpisodeDoc {
  type: 'episode';
  episode: {
    id: number;
    title?: string;
    resource: string;
    shortname?: string;
    description?: string;
    artwork: ESArtwork;
    episode_type: ESEmrapGuideEpisodeType;
    chapter_count?: number;
    extra_chapter_count?: number;
    full_episode: boolean;
    is_free?: boolean;
    publish_date?: string;
    displayorder: number;
    createdate: string;
    duration?: number;
    video_image?: number;
    cme: ESEmrapGuideEpisodeCME;
    preview: boolean;
    on_cdn: boolean;
  };
}

export interface ESEmrapGuideChapterMediaVideo {
  image?: number;
  duration?: number;
}

export interface ESEmrapGuideChapterMediaMd
  extends ESEmrapGuideChapterMediaVideo {
  filename?: string;
  edition?: number;
  faculty?: string[];
}

export type ESEmrapGuideChapterMediaRn = ESEmrapGuideChapterMediaMd;

export interface ESEmrapGuideChapterDoc {
  type: 'chapter';
  episode: Pick<
    ESEmrapGuideEpisodeDoc['episode'],
    | 'id'
    | 'title'
    | 'resource'
    | 'shortname'
    | 'episode_type'
    | 'is_free'
    | 'createdate'
    | 'publish_date'
  >;
  chapter: {
    id: string;
    title: string;
    displayorder: number;
    resource: string;
    shortname: string;
    description?: string;
    artwork: ESArtwork;
    is_free?: boolean;
    tags?: string[];
    comment_count?: string;
    media: {
      video: ESEmrapGuideChapterMediaVideo;
      md?: ESEmrapGuideChapterMediaMd;
      rn?: ESEmrapGuideChapterMediaRn;
    };
    written_summary?: string;
    citation_ids?: number[];
    citations?: string;
    playlists?: string[];
  };
}

export type MediaReport = {
  id: number; // media ID
  seriesType: EpisodeTypeShortname;
  chapterTitle: string;
  episodeTitle: string;
  currentPoint: number;
  duration: number;
  mediaType: 'audio' | 'video';
};
