import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ContributorModal } from '../ChapterPage/ContributorModal';
import { CmeFaculty } from '../../schema/cme/cmeFaculty';
import { CmeEpisodeCredit } from '../../schema/cme/cmeEpisodeCredit';
import { DateWithWordbreak } from '../DateWithWordbreak';
import SubscriptionStatus from '../ChapterPage/SubscriptionStatus';
import { EpisodeTypeShortname } from '../../schema/webEpisode/episodeType';
import { markCreditAsClaimed } from '../../store/cmeEpisodesCredit';
import { CmeEvaluationFlow } from '../CmeCreditsPage/CmeEvaluationFlow';
import { CmeGenericDisclosureModal } from '../CmeCreditsPage/CmeGenericDisclosureModal';
import { getPlanningCommittee } from '../../lib/getPlanningCommittee';
import { getPhpSitePath } from '../../lib/getPhpSitePath';
import { CurrentStep } from '../../lib/cmeEvaluationSteps';
import { getPageSpecialty } from '../../lib/getPageSpecialty';
import { reportModalOpenedAnalytics } from '../../analytics/reportModalOpenedAnalytics';
import { reportLinkClickedAnalytics } from '../../analytics/reportLinkClickedAnalytics';
import { isCmeCreditClaimed } from '../../lib/isCmeCreditClaimed';
import { ToggleHeading } from '../ToggleHeading';
import { Heading } from '../Heading';
import { Button } from '../Button';
import { LinkButton } from '../LinkButton';
import { ButtonLink } from '../ButtonLink';
import { Prose } from '../Prose';
import { CmeCreditLabel } from '../CmeCreditsPage/CmeCreditLabel';

import './CmeCreditsTab.scss';

function getProviderLongDisplay(title: string) {
  if (title === 'PIM') {
    return 'Postgraduate Institute for Medicine';
  }

  return title;
}

interface CmeCreditsTabProps {
  cmeFaculty: CmeFaculty[];
  cmeEpisodeCredit: CmeEpisodeCredit;
  isPaidAndActive: boolean;
  isSignedIn: boolean;
  episodeType: EpisodeTypeShortname;
  isTrial: boolean;
}

export function CmeCreditsTab({
  isSignedIn,
  cmeFaculty,
  cmeEpisodeCredit,
  episodeType,
  isPaidAndActive,
  isTrial
}: CmeCreditsTabProps) {
  const [selectedContributor, setSelectedContributor] =
    useState<CmeFaculty | null>(null);
  const dispatch = useDispatch();
  const [evaluationStep, setEvaluationStep] = useState<CurrentStep>(
    CurrentStep.NONE
  );
  const [isGenericDisclosureModalOpen, setIsGenericDisclosureModalOpen] =
    useState(false);

  const { trialSubscriptionMessaging } = useFlags();

  const {
    startDate,
    disclosure,
    episode,
    overallCreditHours,
    provider,
    releaseDate,
    lastReviewDate,
    expiryDate,
    objectives
  } = cmeEpisodeCredit;

  const planningCommitteeMembers = getPlanningCommittee(episodeType, startDate);
  const pageSpecialty = getPageSpecialty(episodeType);
  const isCreditClaimed = isCmeCreditClaimed(cmeEpisodeCredit);

  const hasPlanningCommitteeMembers = planningCommitteeMembers.length > 0;
  const hasFaculty = cmeFaculty !== null && cmeFaculty.length > 0;

  const paymentUrl = trialSubscriptionMessaging
    ? '/account/payment'
    : '/subscribe';

  return (
    <section className="cme-credits-tab">
      <SubscriptionStatus
        isSignedIn={isSignedIn}
        isPaidAndActive={isPaidAndActive}
        isTrial={isTrial}
        tabName="cme"
        episodeType={episodeType}
      />
      <CmeGenericDisclosureModal
        isModalOpen={isGenericDisclosureModalOpen}
        handleClose={() => setIsGenericDisclosureModalOpen(false)}
        disclosureContent={disclosure !== null ? disclosure : ''}
        isLoading={false}
      />
      {evaluationStep !== CurrentStep.NONE && (
        <CmeEvaluationFlow
          evaluationCredits={[cmeEpisodeCredit]}
          currentStep={evaluationStep}
          setEvaluationStep={setEvaluationStep}
          handleEvaluationFlowCompletion={() =>
            setEvaluationStep(CurrentStep.NONE)
          }
          handleCreditsClaimed={() => {
            dispatch(markCreditAsClaimed({ episodeId: episode.id }));
          }}
          handleClose={() => setEvaluationStep(CurrentStep.NONE)}
        />
      )}
      {selectedContributor && (
        <ContributorModal
          faculty={selectedContributor}
          handleClose={() => setSelectedContributor(null)}
        />
      )}
      <header className="cme-credits-tab__header global-is-stack">
        <Heading Tag="h2" variant="xl">
          CME Credit Details
        </Heading>
        <p className="cme-credits-tab__header-highlight">
          <CmeCreditLabel
            totalCredits={overallCreditHours}
            creditTitle={provider.creditTitle}
            creditTitlePlural={provider.creditTitlePlural}
          />{' '}
          certified by {provider.title}
        </p>
        <div className="cme-credits-tab__header-actions-container">
          {(isPaidAndActive && !isTrial) || isCreditClaimed ? (
            <Button
              variant="primary"
              onClick={() => {
                setEvaluationStep(
                  isCreditClaimed ? CurrentStep.COMPLETE : CurrentStep.CONFIRM
                );
              }}
              label={isCreditClaimed ? 'Get Certificate' : 'Claim this CME'}
            />
          ) : (
            <LinkButton
              href={getPhpSitePath(
                isSignedIn ? paymentUrl : '/account/sign-in'
              )}
              label="Claim this CME"
              variant="secondary"
            />
          )}
          <LinkButton
            to="/account/cme"
            variant="secondary"
            onClick={() =>
              reportLinkClickedAnalytics({
                to: 'account/cme',
                linkText: 'view all my cme',
                pageSpecialty
              })
            }
            label="View all my CME"
          />
        </div>
        <dl className="cme-credits-tab__header-dates">
          <div className="cme-credits-tab__header-date-container">
            <dt className="cme-credits-tab__header-date-name">
              Original Release:
            </dt>
            <dd>
              <DateWithWordbreak timestamp={releaseDate} />
            </dd>
          </div>
          {lastReviewDate && (
            <div className="cme-credits-tab__header-date-container">
              <dt className="cme-credits-tab__header-date-name">
                Recent Review:
              </dt>
              <dd>
                <DateWithWordbreak timestamp={lastReviewDate} />
              </dd>
            </div>
          )}
          <div className="cme-credits-tab__header-date-container">
            <dt className="cme-credits-tab__header-date-name">
              Termination Date:
            </dt>
            <dd>
              <DateWithWordbreak timestamp={expiryDate} />
            </dd>
          </div>
        </dl>
      </header>
      <section className="cme-credits-tab__credit-designation cme-credits-tab__divider global-is-stack">
        <Heading Tag="h2" variant="lg">
          Credit Designation
        </Heading>
        <ToggleHeading
          headingText="Physician Continuing Medical Education"
          nounText="details"
        >
          <p>
            {`${provider.title} designates this enduring material for a maximum of ${overallCreditHours} `}
            <em>AMA PRA Category 1 Credit(s)</em>&trade; per month. Physicians
            should claim only the credit commensurate with the extent of their
            participation in the activity.
          </p>
        </ToggleHeading>
        <ToggleHeading
          headingText="Continuing Nursing Education"
          nounText="details"
        >
          <p>
            The maximum number of hours awarded for this Continuing Nursing
            Education activity is {overallCreditHours} contact hours. Provider
            approved by the California Board of Registered Nursing, Provider
            Number 13485, for {overallCreditHours} contact hours.
          </p>
        </ToggleHeading>
        <ToggleHeading
          headingText="Continuing Physician Assistant Education"
          nounText="details"
        >
          <p>
            {getProviderLongDisplay(provider.title)} has been authorized by the
            American Academy of PAs (AAPA) to award AAPA Category 1 CME credit
            for activities planned in accordance with AAPA CME Criteria. This
            activity is designated for {overallCreditHours} AAPA Category 1 CME
            credits. Approval is valid until{' '}
            <DateWithWordbreak timestamp={expiryDate} />. PAs should only claim
            credit commensurate with the extent of their participation.
          </p>
        </ToggleHeading>
      </section>
      <div className="cme-credits-tab__sidebar">
        <section className="cme-credits-tab__generic-disclosure cme-credits-tab__divider global-is-stack">
          <Button
            variant="secondary"
            onClick={() => {
              reportModalOpenedAnalytics({
                modalType: 'cme generic disclosure',
                triggerText: 'CME Information',
                pageSpecialty
              });
              setIsGenericDisclosureModalOpen(true);
            }}
            label="CME Information"
            className="cme-credits-tab__generic-disclosure-button"
          />
        </section>
        {objectives !== null && objectives.length > 0 && (
          <section className="cme-credits-tab__objectives cme-credits-tab__divider global-is-stack">
            <Heading Tag="h2" variant="lg">
              Objectives
            </Heading>
            <Prose htmlString={objectives} />
          </section>
        )}

        {(hasPlanningCommitteeMembers || hasFaculty) && (
          <section className="cme-credits-tab__contributors cme-credits-tab__divider global-is-stack">
            <Heading Tag="h2" variant="lg">
              Contributors
            </Heading>
            {hasPlanningCommitteeMembers && (
              <>
                <Heading Tag="h3" variant="md">
                  Planning Committee
                </Heading>
                {planningCommitteeMembers.map(member => (
                  <ButtonLink
                    key={member.fullname}
                    onClick={() => {
                      reportModalOpenedAnalytics({
                        modalType: 'cme planning committee disclosure',
                        triggerText: member.fullname,
                        pageSpecialty
                      });
                      setSelectedContributor(member);
                    }}
                  >
                    {member.fullname}
                  </ButtonLink>
                ))}
              </>
            )}
            {hasFaculty && (
              <>
                <Heading Tag="h3" variant="md">
                  Speakers
                </Heading>
                {cmeFaculty.map(faculty => (
                  <button
                    key={faculty.fullname}
                    type="button"
                    className="global-link"
                    onClick={() => {
                      reportModalOpenedAnalytics({
                        modalType: 'cme faculty disclosure',
                        triggerText: faculty.fullname,
                        pageSpecialty
                      });
                      setSelectedContributor(faculty);
                    }}
                  >
                    {faculty.fullname}
                  </button>
                ))}
              </>
            )}
          </section>
        )}
      </div>
    </section>
  );
}
