import { track } from '@amplitude/analytics-browser';
import { MediaReport } from '../lib/types';

type ReportPlaybackBeginAnalyticsParams = MediaReport & {
  isAutoplay?: boolean;
  isNursesEdition?: boolean;
  pageSpecialty?: string;
};

export function reportPlaybackBeginAnalytics({
  id,
  seriesType,
  chapterTitle,
  episodeTitle,
  currentPoint,
  duration,
  mediaType,
  isAutoplay,
  isNursesEdition,
  pageSpecialty
}: ReportPlaybackBeginAnalyticsParams) {
  track('Playback_Begin', {
    'Media.id': id,
    'Media.series_type': seriesType,
    'Media.chapter_title': chapterTitle,
    'Media.episode_title': episodeTitle,
    'Media.current_point': currentPoint,
    'Media.duration': duration,
    'Media.media_type': mediaType,
    autoplay: isAutoplay,
    nurses_edition: isNursesEdition,
    page_specialty: pageSpecialty,
    path: window.location.pathname
  });
}
